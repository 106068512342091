/* (c) George Dantens | george@dantens.com | dantens.com */

$(document).ready(function() {
    
$('.phone').mask('+7 000 000-00-00');

$('.form-valid').each(function() {
  var form = $(this),
      btn = form.find('input[type=submit]'),
      email_pattern = /^\S+@\S+\.\S{2,}$/,
      phone_pattern = /^(?=[^()]*\(([^()]*\)[^()]*)?$|[^()]*$)(?=[\s(]*\+[^+]*$|[^+]*$)([-+.\s()]*\d){10,18}$/;

  form.find('input[type=text].required, input[type=tel].required, input[type=email].required, textarea[type=text].required').addClass('empty'); 
      
    $('input[type=text].required, input[type=tel].required, input[type=email].required, textarea[type=text].required').each(function() {  
      $(this).bind('input change blur', function() {
        if($(this).val() != ''){
            if($(this).hasClass('email')){
              if($(this).val().search(email_pattern) == 0){
                $(this).removeClass('empty').removeClass('error').addClass('valid');
                $(this).parent().find('.error-msg').removeClass('error-msg-visible');
              }else{
                $(this).addClass('empty').addClass('error').removeClass('valid');
                $(this).parent().find('.error-msg').addClass('error-msg-visible');
              }
            }else if($(this).hasClass('phone')){
              if($(this).val().search(phone_pattern) == 0){
                $(this).removeClass('empty').removeClass('error').addClass('valid');
                $(this).parent().find('.error-msg').removeClass('error-msg-visible');
              }else{
                $(this).addClass('empty').addClass('error').removeClass('valid');
                $(this).parent().find('.error-msg').addClass('error-msg-visible');
              }
            }else{
              $(this).removeClass('empty').removeClass('error').addClass('valid');
              $(this).parent().find('.error-msg').removeClass('error-msg-visible');
            }
            btnstatus();
          }else{
            $(this).addClass('empty').addClass('error').removeClass('valid');
            $(this).parent().find('.error-msg').addClass('error-msg-visible');
            btnstatus();
          }
      });
    });
    
/*    $('.name').bind('input change blur', function() {
      $('.client-name').text($(this).val());
    });*/
    
  function errorshow(){
    form.find('.empty').addClass('error');
  }
  
  function errorhide(){
    form.find('.empty').removeClass('error');
  }
  
  function sent(){
    form.each(function(){
      $(this).submit(function(e){
      e.preventDefault();
      e.stopImmediatePropagation();
      var method=$(this).attr('method');
      var action=$(this).attr('action');
      var str=$(this).serialize();
      
        $.ajax({
          type: method,
          url: action,
          data: str,
          cache: false,
          success: function(result) {
            if (result == 'ok') {
                success();
                clearform();
            } else {
              failure();
            }
          }
        });
      return false; 
      });
    });
  }
  
  function success(){
    form.parent('.form-container').each(function() {
      $(this).hide();
      $(this).next('.success').show();
    });
  }
  
  function failure(){
    form.parent('.form-container').each(function() {
      $(this).hide();
      $(this).parent().find('.failure').show();
    });
  }
  
  function clearform(){
    form.trigger('reset');
    form.find('input[type=text].required, input[type=tel].required, input[type=email].required, textarea[type=text].required').removeClass('valid').addClass('empty');
    btn.addClass('disabled');
  }

  function btnstatus(){
    var amt = form.find('.empty').size();
    if (amt > 0){
        if (btn.hasClass('disabled')){
        return false;
        } else {
        btn.addClass('disabled')
        }
    } else {
      btn.removeClass('disabled')
    }
  }

  btn.click(function() {
    if ($(this).hasClass('disabled')){
        errorshow();
        return false;
    } else {
        sent();
    }
  });
    
  $('.send-form-again').click(function() {
    form.parent('.form-container').show();
    $(this).parent().hide();
  });
});
});