/* (c) George Dantens | dantens.com | george@dantens.com */

$(document).ready(function() {
     
/* appearance */
setTimeout(function() {
    $('#layout').addClass('active');
}, 500);

/* nav */
var h = $('.header-inner').outerHeight(),
    h = h;

$('.nav ul li a, .btn-down, .anchor, .header-logo, .footer-logo').click(function(e) {
    e.preventDefault();
    var $this = $(this).attr('href');
    $('html, body').animate({scrollTop: $($this).offset().top - h}, 1000);
});

$(window).scroll(function() {
    var scroll = $(window).scrollTop(),
        w = $( window ).height(),
        dhref = $(this).data('href');
    if (scroll) {
        $('.section').each(function(i) {
            var id = $(this).attr('id');
            if ($(this).position().top <= scroll + w) {
                $('#header').addClass('fixed');
                $('.nav ul li a').removeClass('active');
                $('.nav ul li a[data-href="' + id + '"]').addClass('active');
                $('.section.active').removeClass('active');
                $(this).addClass('active');
            }
        });
    } else {
        $('#header').removeClass('fixed');
        $('.section.active').removeClass('active');
    }
});

/* cover */
function resizeScrenn() {
    if ($(window).width() >= 1024) {
        if (!$('.cover-video > video').length) {
            $('.cover-video').append('<video loop="loop" muted="muted" autoplay="autoplay"><source src="/img/cover.mp4" type="video/mp4"></video>');
        }
    }
}
resizeScrenn();
$(window).resize(function() {
    resizeScrenn();
});
    
/* tabs */
var hash = window.location.hash.replace('#', '');
if(window.location.hash != '') {
	if(hash != 'works' && hash != 'about' && hash != 'footer') {
		var curTabPos = $('.tabs li[data-name="' + hash + '"]').position().left;
		$('.tabs li[data-name="' + hash + '"]').addClass('current');
		$('.tabs').scrollLeft(curTabPos);
		$('.tabs-content .tab-content[data-name="' + hash + '"]').addClass('current');
		$('html, body').animate({scrollTop: $('#services').offset().top - h}, 1000);
	} else {
		$('.tabs .tab:nth-child(1)').addClass('current');
		$('.tabs-content .tab-content:nth-child(1)').addClass('current');
	}
} else {
    $('.tabs .tab:nth-child(1)').addClass('current');
    $('.tabs-content .tab-content:nth-child(1)').addClass('current');
}
/*var hash = window.location.hash.replace('#', '');
if(window.location.hash) {
    var curTabPos = $('.tabs li[data-name="' + hash + '"]').position().left;
    $('.tabs li[data-name="' + hash + '"]').addClass('current');
    $('.tabs').scrollLeft(curTabPos);
    $('.tabs-content .tab-content[data-name="' + hash + '"]').addClass('current');
    $('html, body').animate({scrollTop: $('#services').offset().top - h}, 1000);
} else {
    $('.tabs .tab:nth-child(1)').addClass('current');
    $('.tabs-content .tab-content:nth-child(1)').addClass('current');
}*/
$('.tabs > .tab').click(function(e){
    e.preventDefault();
    var tab = $(this),
        tabIndex = tab.index(),
        tabContent = tab.parent().next().children('div'),
        tabCurrent = 'current';
    if (!($(this).hasClass(tabCurrent))){
        var name = $(this).data('name');
        if (history.pushState) {
            window.history.pushState(null, null, '#' + name);
        } else {
            window.location.hash = '#' + name;
        }
        tab.addClass(tabCurrent).siblings().removeClass(tabCurrent);
        tabContent.removeClass(tabCurrent).eq(tabIndex).addClass(tabCurrent);
        $('.media-preview').removeClass('hidden');
        $('.media-preview + .video > iframe').attr('src', '');
    }
});
    
/* show-video */
$('.show-video').click(function(e) {
    e.preventDefault();
    var src = $(this).data('src');
    $(this).addClass('hidden');
    $(this).next('.video').find('iframe').attr('src', src + '?rel=0&amp;showinfo=0&amp;autoplay=1');
});

/* popup */  
function openPopup(){
    $('.popup').addClass('popup-opened');
    $('.popup-content-' + dpopup).addClass('popup-content-opened popup-content-hidden');
    //$('body').addClass('no-scroll');
}
function closePopup(){
    zoomEnable();
    $('body').removeClass('no-scroll');
    $('.popup-content').removeClass('popup-content-opened');
    setTimeout(function() {
        $('.popup-content').removeClass('popup-content-hidden');
    }, 500);
    $('.popup').removeClass('popup-opened');
    $('.popup-content-video .video > iframe').attr('src', '');
	$('.popup-content-order input[name="subject"]').val('Фидбек');
    setTimeout(function() {
        $('.popup-order-info h2').remove();
    }, 550);
}
$('.open-popup').click(function(e){
    e.preventDefault();
    var dpopup=$(this).data('popup'),
		dpopupSettings=$(this).data('popup-settings');
    if (dpopup === 'video'){
        var src = $(this).data('src');
        $('.popup-content-video .video > iframe').attr('src', src + '?rel=0&amp;showinfo=0&amp;autoplay=1')
    }
    if (dpopup === 'photo'){
        var bg = $(this).css('background-image'),
            src = bg.replace(/.*\s?url\([\'\"]?/, '').replace(/[\'\"]?\).*/, '').replace(/(\.[\w\d_-]+)$/i, '-full$1');
        $('.popup-content-photo .photo > img').attr('src', src)
    }
    if (dpopup === 'order' && dpopupSettings === 'order-from-services'){
        var par = $(this).parents('.service'),
			serviceTitle = par.find('h2').data('title'),
            bg = par.find('.media-preview').css('background-image'),
            bg = bg.replace(/.*\s?url\([\'\"]?/, '').replace(/[\'\"]?\).*/, '');
        par.find('h2').clone().prependTo($('.popup-order-info > .form-container'));
		$('.popup-content-order input[name="subject"]').val(serviceTitle);
        $('.popup-img').css('background-image', 'url(' + bg + ')');
    }
    if (dpopup === 'order' && dpopupSettings === 'order-from-packages'){
        var par = $(this).parents('.package'),
			packageTitle = par.find('h3').data('title');
		$('<h2/>', {text: packageTitle}).prependTo('.popup-order-info > .form-container');
		$('.popup-content-order input[name="subject"]').val(packageTitle);
    }
    $('.popup').addClass('popup-opened');
    $('.popup-content-' + dpopup).addClass('popup-content-opened popup-content-hidden');
    $('body').addClass('no-scroll');
    zoomDisable();
});
$('.popup .popup-close').click(function(){
    closePopup();
});
$(document).mouseup(function(e){
    if($('.popup-content').is(':visible')){
        if(!$(e.target).closest('.popup-content').length){
            closePopup();
        }
    }
});

/* nav */
function openNav(){
    $('#header').addClass('header-opened');
    $('.header-nav-toggle').addClass('active');
    $('body').addClass('no-scroll');
}
function closeNav(){
    $('body').removeClass('no-scroll');
    $('.header-nav-toggle').removeClass('active');	
    $('#header').removeClass('header-opened');										
}
$('.header-nav-toggle').click(function(){
  if(!$(this).hasClass('active')){ openNav(); } 
  else { closeNav(); }
});
$('.nav ul > li > a').click(function(){
  closeNav();
});
	
/* zoom */
function zoomDisable(){
  $('meta[name="viewport"]').attr('content', 'width=device-width, initial-scale=1.0, user-scalable=no');
}
function zoomEnable(){
  $('meta[name="viewport"]').attr('content', 'width=device-width, initial-scale=1.0, user-scalable=yes');
}
$('input[type=text], textarea').on({ 'touchstart' : function(){
    zoomDisable();
}});
$('input[type=text], textarea').on({ 'touchend' : function(){
    zoomDisable();
}});
/*$('input[type=text], textarea').focus(function() {
    zoomDisable();
});
$('input[type=text], textarea').blur(function() {
    zoomEnable();
});*/
});